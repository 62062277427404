import React from "react"
import HTML from "../components/HTML"
import Layout from '../components/layout'

function rawHTML(props) {
  const { pageContext } = props
  const { pageContent, theme, dealerInfo } = pageContext

  return (
    <Layout seo={pageContent.SEO}>
      <HTML key={"HTML"} data={pageContent.HTML} theme={theme} dealer={dealerInfo} />
    </Layout>
  )
}
export default rawHTML