import React from 'react'

import InnerHTML from 'dangerously-set-html-content'

function HTML(props) {
    return (
        <InnerHTML html={props.data.rawHTML} />
    )
}

export default HTML